import gql from 'graphql-tag'

const mutation = gql`
  mutation createReportTransfersPaymentIntentHistories(
    $billingType: String
    $processedAtBeginDate: ISO8601Date!
    $processedAtEndDate: ISO8601Date!
  ) {
    createReportTransfersPaymentIntentHistories(
      billingType: $billingType,
      processedAtBeginDate: $processedAtBeginDate,
      processedAtEndDate: $processedAtEndDate
    ) {
      id
      success
      errors {
        fieldName
        message
      }
    }
}`

async function createReportTransferences ( params = {} ) {
  const { $api } = Vue.prototype

  let beginDate = ''
  let endDate = ''

  if (params.processedAtBeginDate && params.processedAtEndDate) {

    let { processedAtBeginDate, processedAtEndDate } = params

    processedAtBeginDate = processedAtBeginDate.split("/")
    processedAtEndDate = processedAtEndDate.split("/")
    beginDate = new Date(processedAtBeginDate[2], processedAtBeginDate[1] - 1, processedAtBeginDate[0]).toISOString().split("T")[0]
    endDate =  new Date(processedAtEndDate[2], processedAtEndDate[1] - 1, processedAtEndDate[0]).toISOString().split("T")[0]

    params.processedAtBeginDate = beginDate
    params.processedAtEndDate = endDate
  }

  const result = await $api.mutate({
    mutation,
    variables: params
  }).then( res => res )
  .catch( e => e )
  return result;
}

export { createReportTransferences }
