import { mapState, mapActions, mapGetters } from 'vuex'
import { formatTransactionStatus, formatValueCurrency, formatValueTaxes, shortFormatDate, unmaskMoney, checkDate } from '@/helpers'
import { getPaymentIntents } from '@/queries/transferences/transferences.queries'
import { createReportTransference } from '@/queries/transferences/transferences.mutations'
import paginationMixin from '@/mixins/pagination.mixin'
import Vue from 'vue'

export default {
  name: 'TSingleTransference',

  mixins: [ paginationMixin ],

  components: {
    TFilter: () => import('@/components/filter'),
    TTransferenceFilters: () => import('@/components/transferences/filters.vue')
  },

  data() {
    return {
      transferenceId: '',
      tableHeader: [
        { title: 'Status', classes: '', key: 'status', order: null },
        { title: 'Vencimento', classes: '', key: 'dueDate', order: null },
        { title: 'Doador', classes: '', key: 'customerName', order: null },
        { title: 'Método', classes: '', key: 'paymentMethod', order: null },
        { title: 'Tipo', classes: '', key: 'state', order: null },
        { title: 'Taxas', classes: '', key: 'feeAmount', order: null },
        { title: '$ Valor', classes: '', key: 'amount', order: null },
      ],
      modal: '',
      limit: 120,
      routeParams: {
        routeName: 'transactions.single',
        params: [
          { path: 'type', param: 'paymentMethod' },
          { path: 'id', param: 'paymentIntentId' },
        ],
        clickToView: true
      }
    }
  },

  computed: {
    ...mapState({
      transferenceTransactions: ({ transference }) => transference.transferenceTransactions,
      isLoading: ({ transference }) => transference.isLoading,
      filterForm: ({ transference }) => transference.filterForm,
      filterItems: ({ transference }) => transference.filterItems,
      orderParams: ({ transference }) => transference.orderParams,
      headerData: ({ transference }) => transference.headerData,
      metaDataList: ({ transference }) => transference.metaDataList,
      parentItem: ({ table }) => table.item,
      transferenceData: ({ transference }) => transference.transferenceData
    }),

    formattedTitle: ({ headerData }) => `<span class="title">Transferência</span> <span class="date"> - ${headerData.processedAt ? shortFormatDate(headerData.processedAt) : ''}</span>`,

    ...mapGetters({
      user: 'getUser'
    })
  },

  methods: {

    ...mapActions('transference', [
      'setTransferenceTransactions', 'setMetaDataList', 'setOrderParams', 'toggleLoadingList',
      'addItem', 'removeItem', 'clearFilterForm', 'setHeaderData', 'clearFilterItems' ]),
    ...mapActions('filter', [ 'toggleLoading' ]),
    ...mapActions('notifications', [ 'toggleNotification', 'setNotificationMessage' ]),

    applyFilter () {
      const { filterBy, equalTo, fieldOne, fieldTwo } = this.filterForm

      if (filterBy && equalTo && fieldOne) {
        if (!this.filterItemAlreadySelected(filterBy)) {

          let allowAddItem = false

          if (filterBy.key === 'period') {
            if (fieldOne
            && !checkDate(fieldOne)
            && fieldTwo
            && !checkDate(fieldTwo)) {
              allowAddItem = true
            }
          } else if (filterBy.key === 'donationValue') {
            const donationValue = unmaskMoney(fieldOne)
            if (donationValue > 0) {
              allowAddItem = true
            }
          } else {
            allowAddItem = true
          }

          if (allowAddItem) {
            this.toggleLoading(true)
            setTimeout(() => {
              this.addItem({ filterBy, equalTo, fieldOne, fieldTwo })
              this.executeSearch()
              this.clearFilterForm()
              $('.tui.ui.dropdown').dropdown('clear')
              this.toggleLoading()
            }, 1000)
          }
        }
      }
    },

    filterItemAlreadySelected (filterBy) {
      return this.filterItems.length !== 0 && this.filterItems.find(filter => filter.filterBy.key === filterBy.key)
    },

    filtersUpdated (item) {
      this.removeItem(item)
      this.executeSearch()
    },

    executeSearch(params = {}) {
      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }

      this.toggleLoadingList(true)
      getPaymentIntents(searchParams).then(res => {
        const { collection, metadata } = res.data.transferPaymentIntentHistories
        this.setTransferenceTransactions(collection)
        this.setMetaDataList(metadata)
        this.updateMetadataTable()
      }).catch(e => {
        Vue.$log.error('error', e)
      }).finally(() => this.toggleLoadingList())
    },

    mountSearchParams() {
      let params = {}
      params.transferId = this.transferenceId
      const status = this.filterItems.find( item => item.filterBy.key === 'status' )
      const paymentMethod = this.filterItems.find( item => item.filterBy.key === 'paymentMethod' )
      const name = this.filterItems.find( item => item.filterBy.key === 'name' )
      const donationValue = this.filterItems.find( item => item.filterBy.key === 'donationValue' )
      const periodFilter = this.filterItems.find( item => item.filterBy.key === 'period' )

      if (status) {
        params.status = status.fieldOne.key
      }

      if (name) {
        params.customerName = name.fieldOne
      }

      if (paymentMethod) {
        params.paymentMethod = paymentMethod.fieldOne.key
      }

      if (donationValue) {
        params.amountOperator = donationValue.equalTo.key
        params.amount = unmaskMoney(donationValue.fieldOne)
      }

      if (periodFilter) {
        params.dueDateBegin = periodFilter.fieldOne
        params.dueDateEnd = periodFilter.fieldTwo
      }

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.executeSearch()
    },

    formatData(data) {
      return {
        status: formatTransactionStatus(data.status, data.statusLabel),
        dueDate: shortFormatDate(data.dueDate),
        donorName: data.customerName,
        method: data.paymentMethodLabel,
        type: data.stateLabel,
        taxes: formatValueTaxes(data.feeAmount),
        amount: formatValueCurrency(data.amount),
      }
    },

    callCreateReport() {
      this.modal.modal('setting', 'closable', false).modal('show')
    },

    generateReport() {
      const searchParams = this.mountSearchParams()
      createReportTransference(searchParams)
        .then(res => {
          const { success, errors } = res.data.createReportTransferPaymentIntentHistories
          if (!success && errors) {
            const { message } = errors[0]
            this.setNotificationMessage(message)
            this.toggleNotification({ active: true, type: 'error' })
          } else if (success) {
            this.setNotificationMessage("Sua solicitação foi recebida e o arquivo será enviado para o seu e-mail. Isso pode demorar alguns minutos.")
            this.toggleNotification({ active: true, type: 'processing' })
          }
        }).catch(e => {
          Vue.$log.error('error', e)
        })
    },

    bindPlugins() {
      this.modal = $(this.$refs.modalReportTransference).modal()
    },
  },

  mounted() {
    this.clearFilterItems()

    const { id } = this.$route.params
    this.transferenceId = id

    this.setHeaderData(this.transferenceData)

    this.bindPlugins()
    this.executeSearch()
  }
}
