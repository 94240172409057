import gql from 'graphql-tag'

const mutation = gql`
  mutation createReportTransferPaymentIntentHistories(
    $transferId: ID!
    $dueDateBegin: ISO8601Date
    $dueDateEnd: ISO8601Date
    $paymentMethod: String
    $status: String
    $customerName: String
    $amount: Int
    $amountOperator: String
  ) {
    createReportTransferPaymentIntentHistories(
      transferId: $transferId
      dueDateBegin: $dueDateBegin
      dueDateEnd: $dueDateEnd
      paymentMethod: $paymentMethod
      status: $status
      customerName: $customerName
      amount: $amount
      amountOperator: $amountOperator
    ) {
      id
      success
      errors {
        fieldName
        message
      }
    }
}`

async function createReportTransference ( params = {} ) {
  const { $api } = Vue.prototype

  let beginDate = ''
  let endDate = ''

  if (params.dueDateBegin && params.dueDateEnd) {

    let { dueDateBegin, dueDateEnd } = params

    dueDateBegin = dueDateBegin.split("/")
    dueDateEnd = dueDateEnd.split("/")
    beginDate = new Date(dueDateBegin[2], dueDateBegin[1] - 1, dueDateBegin[0]).toISOString().split("T")[0]
    endDate =  new Date(dueDateEnd[2], dueDateEnd[1] - 1, dueDateEnd[0]).toISOString().split("T")[0]

    params.dueDateBegin = beginDate
    params.dueDateEnd = endDate
  }

  const result = await $api.mutate({
    mutation,
    variables: params
  }).then( res => res )
  .catch( e => e )
  return result;
}

export { createReportTransference }
